import { isDev, isStage } from './constants';

export const TAB_SYNC_DURATION = 5000;
export const GET_TAB_SYNC_DURATION = (isCT5 = false) =>
    isCT5 ? 60000 : TAB_SYNC_DURATION; // increase up to 60s for CT5 only
export const STORAGE_RENEW_INTERVAL = 24 * 3600000; // 1 day

export const STORAGE_KEYS = ((canReadKeys = isDev || isStage) => {
    return {
        VM_STATE: canReadKeys ? 'vm_state' : 'vms',
        VM_SYNC_TIMESTAMP: canReadKeys ? 'vm_sync_timestamp' : 'vmst',
        VM_SYNC_TIME: canReadKeys ? 'vm_sync_time' : 'vmsT',
        TAB_ID: canReadKeys ? 'current_tab_id' : 'tbbd',
        TAB_IDS: canReadKeys ? 'existing_tabs' : 'pptbs', // TODO check if it is used
        TAB_START_TIMESTAMP: canReadKeys ? 'start' : '5T@t',
        TAB_START_TIME: canReadKeys ? 'tab start time' : 'stT1m3', // TODO rename to more cryptic name vm_state
        TAB_EXPIRED: canReadKeys ? 'session-expired' : 'se553xp',
        ORDER_STATE: canReadKeys ? 'order_state' : 'orsTt',
        ORDER_SYNC_TIMESTAMP: canReadKeys ? 'order_sync_timestamp' : 'or_5t_tm',
        DEBUG_IS_ALLOWED: 'qrDebug',
        APP_VERS: canReadKeys ? 'app_version' : 'VAp83r51Ya',
        STORE_TIMESTAMP: canReadKeys ? 'store_timestamp' : 'stR-T1m',
        STORE_TIME: canReadKeys ? 'store_time' : 'stRT1m33',
    };
})();

export const DEV_MACHINES = ['600444', '999997', '989001', '600318', '160001'];

// qr codes of machines that are under analysis
export const MACHINES_UNDER_ANALYSIS = [
    '153439',
    '153440',
    '153643',
    '153650',
    '260034',
    '260084',
    '260113',
    '260117',
    '260172',
    '330002',
    '330007',
    '330009',
    '330025',
    '330032',
    '330050',
    '330076',
    '330114',
    '330123',
    '600394',
    '600405',
    '600444',
    '600450',
    '600473',
    '600839',
    '601103',
    '601228',
    '601502',
];
